var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-i ng-scope",attrs:{"ui-view":"mainContent"}},[_c('div',{staticClass:"content-box ng-scope tb-leave disable-scrollbars"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"element-wrapper mb-5"},[_c('h3',{staticClass:"element-header",staticStyle:{"justify-content":"flex-end","display":"flex"}},[_c('a',{staticClass:"ml-2 mb-1 btn ng-binding ng-scope btn-primary",staticStyle:{"margin-top":"5px"},on:{"click":function($event){_vm.listPolicy.addLeave
                  ? _vm.openModalCreateRequest()
                  : _vm.gotoPayment()}}},[_vm._v(" Add Leave ")])]),_c('div',{staticClass:"element-content"},[_c('el-tabs',{staticStyle:{"margin-top":"10px","background":"rgb(248, 248, 248)"},attrs:{"type":"border-card"},on:{"tab-click":_vm.handleClick},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('el-tab-pane',{attrs:{"label":"Submitted","name":"Submitted"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"table-responsive",staticStyle:{"padding-bottom":"0px"}},[_c('table',{staticClass:"table table-padded",staticStyle:{"padding":"0px"}},[_c('thead',[_c('tr',[_c('th',[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.sort('status', false)}}},[_vm._v("Status "),_c('i',{class:_vm.sortKey != 'status'
                                      ? 'icon-sort'
                                      : _vm.reverse
                                      ? 'icon-sort-up'
                                      : 'icon-sort-down'})])]),_c('th',[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.sort('employeeName', false)}}},[_vm._v("Employee "),_c('i',{class:_vm.sortKey != 'employeeName'
                                      ? 'icon-sort'
                                      : _vm.reverse
                                      ? 'icon-sort-up'
                                      : 'icon-sort-down'})])]),_c('th',{staticClass:"text-center"},[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.sort('startDate', true)}}},[_vm._v("Date "),_c('i',{class:_vm.sortKey != 'startDate'
                                      ? 'icon-sort'
                                      : !_vm.reverse
                                      ? 'icon-sort-up'
                                      : 'icon-sort-down'})])]),_c('th',{staticClass:"text-center"},[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.sort('type', false)}}},[_vm._v("Type "),_c('i',{class:_vm.sortKey != 'type'
                                      ? 'icon-sort'
                                      : _vm.reverse
                                      ? 'icon-sort-up'
                                      : 'icon-sort-down'})])]),_c('th',{staticClass:"text-right"},[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.sort('totalHours', true)}}},[_vm._v("Leave "),_c('i',{class:_vm.sortKey != 'totalHours'
                                      ? 'icon-sort'
                                      : !_vm.reverse
                                      ? 'icon-sort-up'
                                      : 'icon-sort-down'})])]),_c('th')])]),_c('tbody',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.more),expression:"more"}]},_vm._l((_vm.listLeaveSub),function(item){return _c('tr',{key:item.id,ref:item.id,refInFor:true,staticClass:"animatedUp ng-scope"},[_c('td',{staticClass:"nowrap"},[(item.status == 'Completing')?_c('span',{staticClass:"badge status ng-binding bg-blue"},[_vm._v("Submitting")]):_c('span',{staticClass:"badge status ng-binding",class:item.status == 'Reject'
                                    ? 'bg-grey'
                                    : item.status == 'Pending'
                                    ? 'bg-yellow'
                                    : 'bg-green'},[_vm._v(_vm._s(item.status))])]),_c('td',[_c('span',{staticClass:"ng-binding"},[_vm._v(_vm._s(item.employeeName))])]),_c('td',{staticClass:"text-center",staticStyle:{"min-width":"140px"}},[_c('span',{staticClass:"ng-binding"},[_vm._v(_vm._s(_vm.formatDate(item.startDate))+" - "+_vm._s(_vm.formatDate(item.endDate)))])]),_c('td',{staticClass:"text-center"},[_c('div',{class:item.type == 'Sick'
                                    ? 'badge ng-binding badge-sick'
                                    : 'badge ng-binding badge-annual'},[_vm._v(" "+_vm._s(item.type)+" ")])]),_c('td',{staticClass:"text-right bolder nowrap"},[_c('span',{staticClass:"ng-binding"},[_vm._v(_vm._s(item.totalHours)+" hours")])]),_c('td',{staticClass:"bolder nowrap text-center",staticStyle:{"padding":"10px 0"}},[_c('button',{staticClass:"btn btn-md action",staticStyle:{"color":"black"},attrs:{"ng-disabled":"loading"},on:{"click":function($event){return _vm.openMore(item.id)}}},[_c('i',{staticClass:"icon-feather-more-horizontal"})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowMore && _vm.id == item.id),expression:"isShowMore && id == item.id"}],staticClass:"layout-more"},[(_vm.UserRole== 'Employee')?_c('button',{directives:[{name:"show",rawName:"v-show",value:(item.status=='Pending'),expression:"item.status=='Pending'"}],staticClass:"btn item-more",staticStyle:{"color":"black"},attrs:{"ng-disabled":"loading"},on:{"click":function($event){_vm.listPolicy.deleteLeave ? _vm.showPopupDelete(item.id) : _vm.gotoPayment()}}},[_c('span',[_vm._v("Delete")])]):_c('button',{staticClass:"btn item-more",staticStyle:{"color":"black"},attrs:{"ng-disabled":"loading"},on:{"click":function($event){_vm.listPolicy.deleteLeave
                                    ? _vm.showPopupDelete(item.id)
                                    : _vm.gotoPayment()}}},[_c('span',[_vm._v("Delete")])]),(_vm.UserRole!= 'Employee')?_c('button',{staticClass:"btn item-more",staticStyle:{"color":"black"},attrs:{"ng-disabled":"loading"},on:{"click":function($event){return _vm.clickStatus()},"mouseover":function($event){return _vm.openStatus()},"mouseout":function($event){return _vm.closeStatus()}}},[_c('span',[_vm._v(" Change Status "),_c('img',{attrs:{"src":"/assets/img/outline_chevron_right_black_18dp.png"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowStatus),expression:"isShowStatus"}],staticClass:"drop-status"},[_c('div',{staticClass:"btn item-more text-success",staticStyle:{"font-weight":"initial"},attrs:{"ng-disabled":"loading"},on:{"click":function($event){_vm.listPolicy.updateLeave
                                        ? _vm.changeStatus(item.id, 'Approved')
                                        : _vm.gotoPayment()}}},[_c('span',[_vm._v("Approved")])]),_c('div',{staticClass:"btn item-more text-yellow",staticStyle:{"font-weight":"initial"},attrs:{"ng-disabled":"loading"},on:{"click":function($event){_vm.listPolicy.updateLeave
                                        ? _vm.changeStatus(item.id, 'Pending')
                                        : _vm.gotoPayment()}}},[_c('span',[_vm._v("Pending")])]),_c('div',{staticClass:"btn item-more text-grey",staticStyle:{"font-weight":"initial"},attrs:{"ng-disabled":"loading"},on:{"click":function($event){_vm.listPolicy.updateLeave
                                        ? _vm.changeStatus(item.id, 'Reject')
                                        : _vm.gotoPayment()}}},[_c('span',[_vm._v("Reject")])])])]):_vm._e(),(_vm.UserRole== 'Employee')?_c('button',{directives:[{name:"show",rawName:"v-show",value:(item.status=='Pending'),expression:"item.status=='Pending'"}],staticClass:"btn item-more",staticStyle:{"color":"black"},attrs:{"ng-disabled":"loading"},on:{"click":function($event){_vm.listPolicy.updateLeave
                                    ? _vm.edit(item.id)
                                    : _vm.gotoPayment()}}},[_c('span',[_vm._v("Edit")])]):_c('button',{staticClass:"btn item-more",staticStyle:{"color":"black"},attrs:{"ng-disabled":"loading"},on:{"click":function($event){_vm.listPolicy.updateLeave
                                    ? _vm.edit(item.id)
                                    : _vm.gotoPayment()}}},[_c('span',[_vm._v("Edit")])])])])}),0)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"bgPagination"},[_c('div',{staticClass:"itemPagination bgNotClick"},[_c('div',{attrs:{"id":"img-left"}})]),_c('ul',{staticClass:"pagination itemPagination"},[_c('li',{staticClass:"bgClick"},[_c('span',[_vm._v("1")])]),_c('li',{staticClass:"bgNotClick"},[_c('span',[_vm._v("2")])]),_c('li',{staticClass:"bgNotClick"},[_c('span',[_vm._v("3")])]),_c('li',{staticClass:"bgNotClick"},[_c('span',[_vm._v("4")])]),_c('li',{staticClass:"bgNotClick"},[_c('span',[_vm._v("5")])])]),_c('div',{staticClass:"itemPagination bgNotClick"},[_c('div',{attrs:{"id":"img-right"}})])])])])]),_c('el-tab-pane',{attrs:{"label":"Taken","name":"Taken"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-padded",staticStyle:{"padding":"0px"}},[_c('thead',[_c('tr',[_c('th',[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.sort('status', false)}}},[_vm._v("Status "),_c('i',{class:_vm.sortKey != 'status'
                                      ? 'icon-sort'
                                      : _vm.reverse
                                      ? 'icon-sort-up'
                                      : 'icon-sort-down'})])]),_c('th',[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.sort('employeeName', false)}}},[_vm._v("Employee "),_c('i',{class:_vm.sortKey != 'employeeName'
                                      ? 'icon-sort'
                                      : _vm.reverse
                                      ? 'icon-sort-up'
                                      : 'icon-sort-down'})])]),_c('th',{staticClass:"text-center"},[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.sort('startDate', true)}}},[_vm._v("Date "),_c('i',{class:_vm.sortKey != 'startDate'
                                      ? 'icon-sort'
                                      : !_vm.reverse
                                      ? 'icon-sort-up'
                                      : 'icon-sort-down'})])]),_c('th',{staticClass:"text-center"},[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.sort('type', false)}}},[_vm._v("Type "),_c('i',{class:_vm.sortKey != 'type'
                                      ? 'icon-sort'
                                      : _vm.reverse
                                      ? 'icon-sort-up'
                                      : 'icon-sort-down'})])]),_c('th',{staticClass:"text-right"},[_c('span',{staticClass:"sort",on:{"click":function($event){return _vm.sort('totalHours', true)}}},[_vm._v("Leave "),_c('i',{class:_vm.sortKey != 'totalHours'
                                      ? 'icon-sort'
                                      : !_vm.reverse
                                      ? 'icon-sort-up'
                                      : 'icon-sort-down'})])]),_c('th')])]),_c('tbody',_vm._l((_vm.listLeaveTaken),function(item){return _c('tr',{key:item.id,ref:item.id,refInFor:true,staticClass:"animatedUp ng-scope"},[_c('td',{staticClass:"nowrap"},[_c('span',{staticClass:"badge status ng-binding bg-green"},[_vm._v(_vm._s(item.status))])]),_c('td',[_c('span',{staticClass:"ng-binding"},[_vm._v(_vm._s(item.employeeName))])]),_c('td',{staticClass:"text-center",staticStyle:{"min-width":"140px"}},[_c('span',{staticClass:"ng-binding"},[_vm._v(_vm._s(_vm.formatDate(item.startDate))+" - "+_vm._s(_vm.formatDate(item.endDate)))])]),_c('td',{staticClass:"text-center"},[_c('div',{class:item.type == 'Sick'
                                    ? 'badge ng-binding badge-sick'
                                    : 'badge ng-binding badge-annual'},[_vm._v(" "+_vm._s(item.type)+" ")])]),_c('td',{staticClass:"text-right bolder nowrap"},[_c('span',{staticClass:"ng-binding"},[_vm._v(_vm._s(item.totalHours)+" hours")])]),_c('td',{staticClass:"bolder nowrap text-center",staticStyle:{"padding":"10px 0"}},[_c('button',{staticClass:"btn btn-md action",staticStyle:{"color":"red"},attrs:{"hidden":!_vm.listPolicy.deleteLeave,"ng-disabled":"loading"},on:{"click":function($event){return _vm.showPopupDelete(item.id)}}},[_c('i',{staticClass:"icon-feather-trash-2"})])])])}),0)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"bgPagination"},[_c('div',{staticClass:"itemPagination bgNotClick"},[_c('div',{attrs:{"id":"img-left"}})]),_c('ul',{staticClass:"pagination itemPagination"},[_c('li',{staticClass:"bgClick"},[_c('span',[_vm._v("1")])]),_c('li',{staticClass:"bgNotClick"},[_c('span',[_vm._v("2")])]),_c('li',{staticClass:"bgNotClick"},[_c('span',[_vm._v("3")])]),_c('li',{staticClass:"bgNotClick"},[_c('span',[_vm._v("4")])]),_c('li',{staticClass:"bgNotClick"},[_c('span',[_vm._v("5")])])]),_c('div',{staticClass:"itemPagination bgNotClick"},[_c('div',{attrs:{"id":"img-right"}})])])])])])],1)],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }