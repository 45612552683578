<template>
  <div class="content-i ng-scope" ui-view="mainContent">
    <div class="content-box ng-scope tb-leave disable-scrollbars">
      <!-- Layout -->
      <div class="row">
        <div class="col-sm-12">
          <div class="element-wrapper mb-5">
            <h3
              class="element-header"
              style="justify-content: flex-end; display: flex">
              <a
                style="margin-top: 5px"
                @click="
                  listPolicy.addLeave
                    ? openModalCreateRequest()
                    : gotoPayment()
                "
                class="ml-2 mb-1 btn ng-binding ng-scope btn-primary">
                Add Leave
              </a>
            </h3>
            <!-- Content -->
            <div class="element-content">
              <el-tabs
                type="border-card"
                v-model="tabs"
                @tab-click="handleClick"
                style="margin-top: 10px; background: rgb(248, 248, 248)"
              >
                <el-tab-pane label="Submitted" name="Submitted">
                  <div class="row">
                    <div class="col">
                      <!-- list data -->
                      <div class="table-responsive" style="padding-bottom: 0px">
                        <table class="table table-padded" style="padding: 0px">
                          <thead>
                            <tr>
                              <th>
                                <span
                                  v-on:click="sort('status', false)"
                                  class="sort"
                                  >Status
                                  <i
                                    :class="
                                      sortKey != 'status'
                                        ? 'icon-sort'
                                        : reverse
                                        ? 'icon-sort-up'
                                        : 'icon-sort-down'
                                    "
                                  ></i
                                ></span>
                              </th>
                              <th>
                                <span
                                  v-on:click="sort('employeeName', false)"
                                  class="sort"
                                  >Employee
                                  <i
                                    :class="
                                      sortKey != 'employeeName'
                                        ? 'icon-sort'
                                        : reverse
                                        ? 'icon-sort-up'
                                        : 'icon-sort-down'
                                    "
                                  ></i
                                ></span>
                              </th>
                              <th class="text-center">
                                <span
                                  v-on:click="sort('startDate', true)"
                                  class="sort"
                                  >Date
                                  <i
                                    :class="
                                      sortKey != 'startDate'
                                        ? 'icon-sort'
                                        : !reverse
                                        ? 'icon-sort-up'
                                        : 'icon-sort-down'
                                    "
                                  ></i
                                ></span>
                              </th>
                              <th class="text-center">
                                <span
                                  v-on:click="sort('type', false)"
                                  class="sort"
                                  >Type
                                  <i
                                    :class="
                                      sortKey != 'type'
                                        ? 'icon-sort'
                                        : reverse
                                        ? 'icon-sort-up'
                                        : 'icon-sort-down'
                                    "
                                  ></i
                                ></span>
                              </th>
                              <th class="text-right">
                                <span
                                  v-on:click="sort('totalHours', true)"
                                  class="sort"
                                  >Leave
                                  <i
                                    :class="
                                      sortKey != 'totalHours'
                                        ? 'icon-sort'
                                        : !reverse
                                        ? 'icon-sort-up'
                                        : 'icon-sort-down'
                                    "
                                  ></i
                                ></span>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody v-click-outside="more">
                            <tr
                              v-for="item in listLeaveSub"
                              :key="item.id"
                              :ref="item.id"
                              class="animatedUp ng-scope"
                            >
                              <td class="nowrap">
                                <span
                                  v-if="item.status == 'Completing'"
                                  class="badge status ng-binding bg-blue"
                                  >Submitting</span
                                >
                                <span
                                  v-else
                                  class="badge status ng-binding"
                                  v-bind:class="
                                    item.status == 'Reject'
                                      ? 'bg-grey'
                                      : item.status == 'Pending'
                                      ? 'bg-yellow'
                                      : 'bg-green'
                                  "
                                  >{{ item.status }}</span
                                >
                              </td>
                              <td>
                                <span class="ng-binding">{{
                                  item.employeeName
                                }}</span>
                              </td>
                              <td style="min-width: 140px" class="text-center">
                                <span class="ng-binding"
                                  >{{ formatDate(item.startDate) }} -
                                  {{ formatDate(item.endDate) }}</span
                                >
                                <!-- <span class="smaller lighter ml-0 ng-binding" style="padding-left: 10px;">{{item.payPeriod}}</span> -->
                              </td>
                              <td class="text-center">
                                <div
                                  v-bind:class="
                                    item.type == 'Sick'
                                      ? 'badge ng-binding badge-sick'
                                      : 'badge ng-binding badge-annual'
                                  "
                                >
                                  {{ item.type }}
                                </div>
                              </td>
                              <td class="text-right bolder nowrap">
                                <span class="ng-binding"
                                  >{{ item.totalHours }} hours</span
                                >
                              </td>
                              <td
                                class="bolder nowrap text-center"
                                style="padding: 10px 0"
                              >
                                <button
                                  class="btn btn-md action"
                                  style="color: black"
                                  @click="openMore(item.id)"
                                  ng-disabled="loading"
                                >
                                  <i class="icon-feather-more-horizontal"></i>
                                </button>
                              </td>
                              <div
                                class="layout-more"
                                v-show="isShowMore && id == item.id"
                              >
                                <button v-if="UserRole== 'Employee'" v-show="item.status=='Pending'"
                                  class="btn item-more"
                                  style="color: black"
                                  @click="listPolicy.deleteLeave ? showPopupDelete(item.id) : gotoPayment()"
                                  ng-disabled="loading">
                                  <span>Delete</span>
                                </button>
                                <button v-else
                                  class="btn item-more"
                                  style="color: black"
                                  @click="
                                    listPolicy.deleteLeave
                                      ? showPopupDelete(item.id)
                                      : gotoPayment()
                                  "
                                  ng-disabled="loading"
                                >
                                  <span>Delete</span>
                                </button>
                                <button v-if="UserRole!= 'Employee'"
                                  class="btn item-more"
                                  style="color: black"
                                  @click="clickStatus()"
                                  @mouseover="openStatus()"
                                  @mouseout="closeStatus()"
                                  ng-disabled="loading"
                                >
                                  <span>
                                    Change Status
                                    <img
                                      src="/assets/img/outline_chevron_right_black_18dp.png"
                                    />
                                  </span>
                                  <div
                                    v-show="isShowStatus"
                                    class="drop-status"
                                  >
                                    <div
                                      class="btn item-more text-success"
                                      style="font-weight: initial"
                                      @click="
                                        listPolicy.updateLeave
                                          ? changeStatus(item.id, 'Approved')
                                          : gotoPayment()
                                      "
                                      ng-disabled="loading"
                                    >
                                      <span>Approved</span>
                                    </div>
                                    <div
                                      class="btn item-more text-yellow"
                                      style="font-weight: initial"
                                      @click="
                                        listPolicy.updateLeave
                                          ? changeStatus(item.id, 'Pending')
                                          : gotoPayment()
                                      "
                                      ng-disabled="loading"
                                    >
                                      <span>Pending</span>
                                    </div>
                                    <div
                                      class="btn item-more text-grey"
                                      style="font-weight: initial"
                                      @click="
                                        listPolicy.updateLeave
                                          ? changeStatus(item.id, 'Reject')
                                          : gotoPayment()
                                      "
                                      ng-disabled="loading"
                                    >
                                      <span>Reject</span>
                                    </div>
                                  </div>
                                </button>
                                <button v-if="UserRole== 'Employee'" v-show="item.status=='Pending'"
                                  class="btn item-more"
                                  style="color: black"
                                  @click="
                                    listPolicy.updateLeave
                                      ? edit(item.id)
                                      : gotoPayment()
                                  "
                                  ng-disabled="loading"
                                >
                                  <span>Edit</span>
                                </button>
                                <button v-else
                                  class="btn item-more"
                                  style="color: black"
                                  @click="
                                    listPolicy.updateLeave
                                      ? edit(item.id)
                                      : gotoPayment()
                                  "
                                  ng-disabled="loading"
                                >
                                  <span>Edit</span>
                                </button>
                              </div>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="bgPagination" v-show="false">
                        <div class="itemPagination bgNotClick">
                          <div id="img-left"></div>
                        </div>
                        <ul class="pagination itemPagination">
                          <li class="bgClick"><span>1</span></li>
                          <li class="bgNotClick"><span>2</span></li>
                          <li class="bgNotClick"><span>3</span></li>
                          <li class="bgNotClick"><span>4</span></li>
                          <li class="bgNotClick"><span>5</span></li>
                        </ul>
                        <div class="itemPagination bgNotClick">
                          <div id="img-right"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>

                <el-tab-pane label="Taken" name="Taken">
                  <div class="row">
                    <div class="col">
                      <!-- list data -->
                      <div class="table-responsive">
                        <table class="table table-padded" style="padding: 0px">
                          <thead>
                            <tr>
                              <th>
                                <span
                                  v-on:click="sort('status', false)"
                                  class="sort"
                                  >Status
                                  <i
                                    :class="
                                      sortKey != 'status'
                                        ? 'icon-sort'
                                        : reverse
                                        ? 'icon-sort-up'
                                        : 'icon-sort-down'
                                    "
                                  ></i
                                ></span>
                              </th>
                              <th>
                                <span
                                  v-on:click="sort('employeeName', false)"
                                  class="sort"
                                  >Employee
                                  <i
                                    :class="
                                      sortKey != 'employeeName'
                                        ? 'icon-sort'
                                        : reverse
                                        ? 'icon-sort-up'
                                        : 'icon-sort-down'
                                    "
                                  ></i
                                ></span>
                              </th>
                              <th class="text-center">
                                <span
                                  v-on:click="sort('startDate', true)"
                                  class="sort"
                                  >Date
                                  <i
                                    :class="
                                      sortKey != 'startDate'
                                        ? 'icon-sort'
                                        : !reverse
                                        ? 'icon-sort-up'
                                        : 'icon-sort-down'
                                    "
                                  ></i
                                ></span>
                              </th>
                              <th class="text-center">
                                <span
                                  v-on:click="sort('type', false)"
                                  class="sort"
                                  >Type
                                  <i
                                    :class="
                                      sortKey != 'type'
                                        ? 'icon-sort'
                                        : reverse
                                        ? 'icon-sort-up'
                                        : 'icon-sort-down'
                                    "
                                  ></i
                                ></span>
                              </th>
                              <th class="text-right">
                                <span
                                  v-on:click="sort('totalHours', true)"
                                  class="sort"
                                  >Leave
                                  <i
                                    :class="
                                      sortKey != 'totalHours'
                                        ? 'icon-sort'
                                        : !reverse
                                        ? 'icon-sort-up'
                                        : 'icon-sort-down'
                                    "
                                  ></i
                                ></span>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in listLeaveTaken"
                              :key="item.id"
                              :ref="item.id"
                              class="animatedUp ng-scope"
                            >
                              <td class="nowrap">
                                <!-- <span class="status-pill smaller green" v-bind:class="(item.status == 'Reject')?'red': (item.status == 'Pending')? 'yellow' : ''"></span> -->
                                <span
                                  class="badge status ng-binding bg-green"
                                  >{{ item.status }}</span
                                >
                              </td>
                              <td>
                                <span class="ng-binding">{{
                                  item.employeeName
                                }}</span>
                              </td>
                              <td style="min-width: 140px" class="text-center">
                                <span class="ng-binding"
                                  >{{ formatDate(item.startDate) }} -
                                  {{ formatDate(item.endDate) }}</span
                                >
                                <!-- <span class="smaller lighter ml-0 ng-binding" style="padding-left: 10px;">{{item.payPeriod}}</span> -->
                              </td>
                              <td class="text-center">
                                <div
                                  v-bind:class="
                                    item.type == 'Sick'
                                      ? 'badge ng-binding badge-sick'
                                      : 'badge ng-binding badge-annual'
                                  "
                                >
                                  {{ item.type }}
                                </div>
                              </td>
                              <td class="text-right bolder nowrap">
                                <span class="ng-binding"
                                  >{{ item.totalHours }} hours</span
                                >
                              </td>
                              <td
                                class="bolder nowrap text-center"
                                style="padding: 10px 0"
                              >
                                <button
                                  v-bind:hidden="!listPolicy.deleteLeave"
                                  class="btn btn-md action"
                                  style="color: red"
                                  @click="showPopupDelete(item.id)"
                                  ng-disabled="loading"
                                >
                                  <i class="icon-feather-trash-2"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="bgPagination" v-show="false">
                        <div class="itemPagination bgNotClick">
                          <div id="img-left"></div>
                        </div>
                        <ul class="pagination itemPagination">
                          <li class="bgClick"><span>1</span></li>
                          <li class="bgNotClick"><span>2</span></li>
                          <li class="bgNotClick"><span>3</span></li>
                          <li class="bgNotClick"><span>4</span></li>
                          <li class="bgNotClick"><span>5</span></li>
                        </ul>
                        <div class="itemPagination bgNotClick">
                          <div id="img-right"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_LIEST_LEAVE,
  GET_EMPLOYEELIST_LEAVE,
  UPDATE_LEAVE,
} from "@/store/actionsType";

import * as Session from "@/utils/SessionStore";
import * as Utils from "@/utils/Utils";
import * as Logs from "@/utils/Logs";

export default {
  name: "Leave",
  props: [],
  components: {},
  data() {
    return {
      listPolicy: null,
      tabs: "Submitted",
      sortKey: "",
      reverse: false,
      listLeaveSub: [],
      listLeaveTaken: [],
      isShowMore: false,
      isShowStatus: false,
      UserRole: this.$root.$refs.BaseLayout.UserRole,
      id: 0,
      more: {
        handler: this.outsideTable,
        middleware: this.middlewareMore,
        events: ["dblclick", "click"],
      },
    };
  },
  created: function () {
    this.$root.$refs.Leave = this;
    this.listPolicy = this.$root.$refs.BaseLayout.listPolicy;
    this.getListLeave();
  },
  methods: {
    sort(column, boolean) {
      if (this.sortKey != column) {
        this.reverse = boolean;
        this.sortKey = column;
      } else {
        this.reverse = !this.reverse;
      }
      Utils.sortListJson(this.listLeaveSub, this.sortKey, this.reverse);
      Utils.sortListJson(this.listLeaveTaken, this.sortKey, this.reverse);
    },
    middlewareMore() {
      if (this.tabs == "Submitted") {
        return true;
      } else {
        return false;
      }
    },
    middlewareMoreTaken() {
      if (this.tabs == "Taken") {
        return true;
      } else {
        return false;
      }
    },
    gotoPayment() {
      this.$root.$refs.BaseLayout.gotoPayment();
    },
    openModalCreateRequest() {
      if (this.$root.$refs.BaseLayout != undefined) {
        this.$root.$refs.BaseLayout.openModalCreateRequest();
      }
    },
    showPopupDelete(idLeave) {
      this.openMore(idLeave);
      Session.set("idLeave", idLeave);
      var elem = window.$("#popupDeleteLeave");
      elem.removeClass("hide-popup");
    },
    formatDate(inputDate) {
      return Utils.formatDateToString(inputDate);
    },
    calculateDate(startDate, endDate) {
      var star = new Date(startDate);
      var end = new Date(endDate);
      var date = end.getTime() - star.getTime();
      var Difference_In_Days = Math.round(date / (1000 * 3600 * 24));
      return Difference_In_Days + 1;
    },
    reloadDataLeave(isReload) {
      if (isReload) {
        this.getListLeave();
      }
    },
    // Request API
    getListLeave() {
      if (this.UserRole == "Employee") {
        if (Session.has("currentEmployeeId")) {
          this.$root.$refs.AppCyrus.start();
          this.$store
            .dispatch(GET_EMPLOYEELIST_LEAVE, Session.get("currentEmployeeId"))
            .then((response) => {
              this.$root.$refs.AppCyrus.finish();
              this.listLeaveSub = [];
              this.listLeaveTaken = [];
              for (var i = 0; i < response.length; i++) {
                if (response[i].status == "Completed") {
                  this.listLeaveTaken.push(response[i]);
                } else {
                  this.listLeaveSub.push(response[i]);
                }
              }
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        } else {
         // this.$root.$refs.BaseLayout.logout();
        }
      } else {
        if (Session.has("currenCompanyId")) {
          this.$root.$refs.AppCyrus.start();
          this.$store
            .dispatch(GET_LIEST_LEAVE, Session.get("currenCompanyId"))
            .then((response) => {
              this.$root.$refs.AppCyrus.finish();
              this.listLeaveSub = [];
              this.listLeaveTaken = [];
              for (var i = 0; i < response.length; i++) {
                if (response[i].status == "Completed") {
                  this.listLeaveTaken.push(response[i]);
                } else {
                  this.listLeaveSub.push(response[i]);
                }
              }
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        } else {
          if (this.$root.$refs.BaseLayout != undefined) {
            this.$root.$refs.BaseLayout.logout();
          }
        }
      }
    },
    openMore(id) {
      this.isShowStatus = false;
      if (this.isShowMore && this.id == id) {
        this.isShowMore = false;
        this.$refs[id][0].classList.remove("transform");
      } else {
        window.$(".transform").removeClass("transform");
        this.isShowMore = true;
        this.$refs[id][0].classList.add("transform");
        this.id = id;
      }
    },
    outsideTable() {
      if (this.isShowMore) {
        this.openMore(this.id);
      }
    },
    clickStatus() {
      this.isShowStatus = !this.isShowStatus;
    },
    openStatus() {
      this.isShowStatus = true;
    },
    closeStatus() {
      this.isShowStatus = false;
    },
    changeStatus(id, status) {
      this.openMore(id);
      this.isShowMore = false;
      for (var i = 0; i < this.listLeaveSub.length; i++) {
        if (this.listLeaveSub[i].id == id) {
          var requestModel = {
            id: this.listLeaveSub[i].id,
            companyId: Session.get("currenCompanyId"),
            employeeId: this.listLeaveSub[i].employeeId,
            employeeName: this.listLeaveSub[i].employeeName,
            type: this.listLeaveSub[i].type,
            payPeriod: this.listLeaveSub[i].payPeriod,
            startDate: this.listLeaveSub[i].startDate,
            endDate: this.listLeaveSub[i].endDate,
            totalHours: this.listLeaveSub[i].totalHours,
            note: this.listLeaveSub[i].note,
            workHoursAtTime: this.listLeaveSub[i].workHoursAtTime,
            status: status,
          };
          break;
        }
      }
      Logs.json("request model", requestModel);
      this.$store
        .dispatch(UPDATE_LEAVE, requestModel)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          Logs.json("change status success", response);
          this.getListLeave();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    edit(id) {
      this.openMore(id);
      this.isShowMore = false;
      var currentLeave = "";
      for (var i = 0; i < this.listLeaveSub.length; i++) {
        if (this.listLeaveSub[i].id == id) {
          currentLeave = this.listLeaveSub[i];
        }
      }
      console.log("currentLeave", currentLeave);
      this.$modal.show("modal-createrequest", {
        leaveEdit: currentLeave,
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.text-grey {
  color: rgb(145, 150, 150);
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}

.text-yellow {
  color: #ffd651;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
table tr.animatedUp:last-child .layout-more{ top:auto; bottom:0;}
</style>
